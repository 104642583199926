.photos-gallery {
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: fixed;
    width: 70vw;
    height: 70vh;
    background: #FFFFFF;
    box-shadow: 10px 10px 24px 0 rgba(131, 111, 255, 0.15);
    border: 1px solid #D8D1FF;
    z-index: 50;
    border-radius: 15px;
    padding: 50px;
    overflow: auto;
}

.photos-gallery-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: 0;
    padding: 10px;
    cursor: pointer;
}

.photos-gallery-remove-button {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border: 0;
    padding: 5px;
    cursor: pointer;
}

.photos-gallery-content {
    background: #FFFFFF;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
}

.photos-gallery-image-container {
    position: relative;
    padding: 10px;
    margin: 10px;
    border: 1px solid #D8D1FF;
    box-shadow: 10px 10px 24px 0 rgba(131, 111, 255, 0.15);
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.photos-gallery-image {
    max-width: 100%;
    max-height: 100%;
}

.photos-gallery-header {
    margin-bottom: 20px;
}

.empty-photos-gallery-header {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}