.alert-modal {
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: fixed;
    width: 350px;
    height: 200px;
    background: #FFFFFF;
    box-shadow: 10px 10px 24px 0 rgba(131, 111, 255, 0.15);
    border: 1px solid #D8D1FF;
    z-index: 100;
    border-radius: 15px;
    padding: 50px;
}

.alert-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    height: 100%;
}

.photos-modal-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: 0;
    padding: 10px;
    cursor: pointer;
}